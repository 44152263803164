body {
  font-family: Futura Light BT, Futura Medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Futura Medium;
  src: local("Futura Medium"),
       url(./fonts/Futura_Medium_BT.ttf);
}

@font-face {
  font-family: Futura Light BT;
  src: local("Futura Light BT"),
       url(./fonts/Futura_Light_BT.ttf);
}

@font-face {
  font-family: Futura Book;
  src: local("Futura Book"),
       url(./fonts/Futura_Book_font.ttf);
}

@media screen and (max-width: 680px) {
  body{
    display: none;
  }
} 
