#calendar {
  --grey-200: #F8F8F8;
  --grey-300: #EDEDED;
  --grey-500: #858585;
  --grey-900: #000000;
  --grey-scroll: #aaaaaa;
  --gold: #B6975A;
  --white: #ffffff;
  --futura-book: 'Futura Book';
  --divider-width: 1.2px
}

.fc .fc-timeline-slot-cushion {
  font-size: 13px;
  font-family: var(--futura-book);
  color: var(--grey-500);
  font-weight: 300;
}

.fc .fc-resource-timeline-divider {
  width: var(--divider-width);
  cursor: unset;
  background: var(--grey-300);
}

.fc .fc-timeline-slots > table {
  border-color: var(--grey-300);
  border-style: solid;
  border-width: var(--divider-width);
  border-left: 0px;
}

.view-month-even .fc .fc-scrollgrid-section table tbody tr.fc-timeline-header-row:nth-child(1) > th:nth-child(even){
  background:var(--grey-200);
}
.view-month-even .fc .fc-scrollgrid-section table tbody tr.fc-timeline-header-row:nth-child(1) > th:nth-child(odd){
  background:var(--grey-300);
}
.view-month-odd .fc .fc-scrollgrid-section table tbody tr.fc-timeline-header-row:nth-child(1) > th:nth-child(odd){
  background:var(--grey-200);
}
.view-month-odd .fc .fc-scrollgrid-section table tbody tr.fc-timeline-header-row:nth-child(1) > th:nth-child(even){
  background:var(--grey-300);
}

.fc .fc-resource-timeline-divider:before {
  content: "";
  width: var(--divider-width);
  height: 40px;
  position: absolute;
  top: -40px;
  background: inherit;
}

td.fc-timeline-slot.fc-timeline-slot-lane:nth-child(even) {
  background-color: var(--grey-300);
}

td.fc-timeline-slot.fc-timeline-slot-lane:nth-child(odd) {
  background-color: var(--grey-200);
}

table.fc-scrollgrid-sync-table tbody tr:first-child td {
  padding-top: 24px;
}

/* now indicator round */
.fc .fc-timeline-now-indicator-arrow {
  border-color: transparent
}

.fc-timeline-now-indicator-arrow:after {
  content: "";
  background-color: var(--gold);
  border-color: var(--gold);
  border-radius: 100%;
  margin: 17px -6px;
  border-width: 6px;
  padding: 6px;
  position: absolute;
}

/* now indicator line */
.fc .fc-timeline-now-indicator-line {
  border: solid var(--gold);
  border-width: 0 0 0 1px;
}

/* Scrolll */

.fc-scroller::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.fc-scroller::-webkit-scrollbar-thumb {
  border-radius: 13px;
  background-color: var(--grey-scroll);
  border: none;
  scrollbar-width: thin;
}

.fc-h-event {
  border: 1px solid #e0e0e0;
  background-color: var(--white) ;
}

.fc-timeline-event {
  border-radius: 4px;
}

.fc-timeline-event:before {
  border: unset!important;
}

.fc-icon-minus-square:before {
  content: url("../../../public/icons/arrow-down.svg");
  position: absolute;
  left: 10px;
  top: 0px;
  cursor: pointer;
  padding: 4px;
}

.fc-icon-plus-square:before {
  content: url("../../../public/icons/arrow-down.svg");
  position: absolute;
  rotate: 270deg;
  left: 10px;
  top: 0px;
  cursor: pointer;
  padding: 4px;
}

.fc-icon-plus-square,
.fc-icon-minus-square {
  height: 0;
  width: 0;
}

@-moz-document url-prefix() { 
  .fc-icon-minus-square:before{
    right: 38px;
  }
  .fc-icon-plus-square:before{
    right: 38px;
  }
}
.fc-datagrid-cell-main {
  user-select: none;
}

.fc .fc-datagrid-cell-cushion {
  padding-top: 2px;
  padding-right: 52px;
  white-space: break-spaces;
}

/* should not be clickable */
span.fc-datagrid-expander {
  position: absolute;
  right: 31px;
  height: 0;
  width: 0;
}

.fc-theme-standard td,
  .fc-theme-standard .fc-scrollgrid,
  .fc-direction-ltr .fc-timeline-slot,
  .fc-theme-standard th {
  border-color: transparent;
}

.fc-timeline-event:not(.fc-event-start):before, .fc-timeline-event:not(.fc-event-end):after {
  border: none;
}

.fc-timeline-event-harness {
  cursor: pointer;
}

.fc-event:focus:after {
  background-color: transparent;
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard > div > div > table > colgroup > col:nth-child(1) {
  transition: 0.75s ease-in-out;
}

table.fc-datagrid-body.fc-scrollgrid-sync-table {
  width: 250px !important;
}

span + span + span + .fc-datagrid-cell-main {
  font-weight: 300;
}

.fc-datagrid-cell-main {
  font-family:  var(--futura-book);
  font-size: 13px;
}

.fc-scroller-harness > .fc-scroller-liquid-absolute {
  overflow-x: hidden !important;
}

