.container{
    z-index: 12;
    position: absolute;
    height: 0;
    top: 165px;
    right: 185px;
    background-color: #ffffff00;
}

.calendar-backdrop{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff00;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color:#424242;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span{
    z-index: 2;
    color: white;

}

.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span{
    color: white;

}

.rdrDayToday .rdrDayNumber span:after {
    background-color: #B6975A;
    left: 18px;
}


.rdrStartEdge {
    z-index: 1;
    background-color: #B6975A;
    border-bottom-right-radius: 1.042em;
    border-top-right-radius: 1.042em;
}
.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after{
    background-color: #B6975A;
    left: 20px;

}
.rdrDateDisplayItemActive {
    border-color: white; 
}
.rdrEndEdge {
    background-color: #B6975A;
    border-bottom-left-radius: 1.042em;
    border-top-left-radius: 1.042em
}

.rdrWeekDay {
    color: rgb(0, 0, 0);
}

.rdrDateDisplay {
    display: initial;
    top: 13px;
    position: relative;
}

.rdrDateDisplayItem {
    border-radius: 0px; 
    background-color: rgb(255, 255, 255); 
    box-shadow: 0 0px 0px 0 rgb(35 57 66 / 21%);
    border-bottom-color: rgb(0, 0, 0);
}


.rdrCalendarWrapper {
    box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 9%);
    border-radius: 4px;
    width: 21.72em;
    
}

.rdrDateDisplayWrapper {
    background-color: white;
}

.rdrNextPrevButton {
    background: #ffffff00;
    left: 22em;
    position: relative;
}
.rdrPprevButton {
    top: 37px;
    left: 185px;
}
.rdrNextButton {
    bottom: 5px;
    left: 200px;
}
.rdrInRange {
    width: 49px;
    left: -9px;
}
.rdrNextPrevButton:hover {
    background: #ffffff00;
}

.rdrDateDisplayItemActive input {
    color: #000000;
}

.rdrDateDisplayItem input {
    text-align: start;
    cursor: default;
    color: #000000;
    width: 90%;
    height:3em;
    bottom: 4px;
    left:25px;
    position: relative;
}

.rdrMonthAndYearWrapper {
    display: contents;
}
.rdrMonthAndYearPickers {
    justify-content: flex-start;
    left: 16px;
    width: 61%;
    font-weight: 100;
    top: 10px;
    position: relative;
}
.rdrMonth {
    width: 20.667em;
}

.rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-style: solid;
    border-width: 0.25px 0.25px 0 0;
    content: '';
    border-color: black black black;
    left: 0;
	transform: rotate(45deg);
    display: inline-block;
    height: 7px;
    vertical-align: top;
    width: 7px;
}
.rdrPprevButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-style: solid;
    border-width: 0.25px 0.25px 0 0;
    content: '';
    border-color: black black black;
    left: 0;
	transform: rotate(224deg);
    display: inline-block;
    height: 7px;
    vertical-align: top;
    width: 7px;
}
.start-date {
    position: relative;
    left: 55px;
    top: 16px;
    z-index: 1;
}
.end-date {
    position: relative;
    left: 32px;
    top: 56px;
    z-index: 1;

}
.line{
    position: absolute;
    top: 92px;
    border-top: 10px;
    left: 52px;
    width: 82%;
}