.icon-svg-list {
  stroke: black;
  stroke-width: 0.95;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon-svg-arrow-circle {
  stroke: #000;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round
}

.icon-svg-arrow-circle-miter {
  stroke-miterlimit: 10;
}

.icon-svg-calendar-border {
  stroke: black;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.icon-svg-calendar-days {
 stroke: black;
 stroke-width: 2;
 stroke-linecap: round;
 stroke-linejoin: round;
}

.icon-svg-arrow-long {
  stroke: black;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
}
